import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import '../assets/stylesheets/pages/portfolioTemplate.scss'

import Layout from '../components/Layout'
import Helmet from 'react-helmet'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  //get image
  let featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid

  let textDown
  if (frontmatter.type.toLowerCase() === 'website') {
    textDown = 'Visit the website now...'
  } else if (frontmatter.type.toLowerCase() === 'app') {
    textDown = 'Download the app now...'
  } else {
    textDown = 'Look at the end result now'
  }

  console.log(frontmatter.noIndex)

  return (
    <Layout>
      <Helmet title={frontmatter.title + ' | Dirk Mulder Portfolio'}>
        <meta charSet="utf-8" />
        <meta name="description" content={frontmatter.excerpt} />
        <html lang="en" />
        <meta
          name="robots"
          content={frontmatter.noIndex ? 'noindex' : 'index'}
        />
      </Helmet>

      <div className="portfolio-post-container">
        <Img fluid={featuredImgFluid} />

        <div className="portfolio-post">
          <div className="portfolio-title">
            <div className="title-inner">
              {frontmatter.type && <h3>{frontmatter.type}</h3>}
              <h1>{frontmatter.title}</h1>
            </div>
          </div>

          <div className="post-wrapper">
            <div
              className="portfolio-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />

            {frontmatter.liveURL && (
              <div className="liveURL">
                <h2>Look at the live result</h2>
                <a href={frontmatter.liveURL}>{textDown}</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        type
        liveURL
        excerpt
        noIndex
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
